const VintedItemReducer = (state, action) => {
  switch (action.type) {
    case "ADD": {
      return {
        vintedItem: action.payload,
      };
    }
    case "REMOVE": {
      return {
        vintedItem: null,
      };
    }
    default:
      return state;
  }
};

export default VintedItemReducer;
