import { deleteDoc, doc, getDoc, updateDoc } from "@firebase/firestore";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Chip,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
  Divider,
  CardFooter,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Images from "../../components/images/Images";
import { db } from "../../firebase";
import { Footer, Header } from "./actions/Actions";
import TickIcon from "../../icons/TickIcon";
import EditIcon from "../../icons/EditIcon";
import CrossIcon from "../../icons/CrossIcon";

export default function SelectedItem(props) {
  const [data, setData] = useState();

  const fetch = async () => {
    try {
      const docRef = doc(db, "items", props.itemId);
      const docSnap = await getDoc(docRef);
      setData({ ...docSnap.data(), id: props.itemId });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetch();
  }, [props]);

  const [modal, setModal] = useState();

  const [searchParams, setSearchParams] = useSearchParams();

  const handleEdit = () => {
    searchParams.delete("item");
    searchParams.append("editItem", props.itemId);
    setSearchParams(searchParams);
  };

  const handleClose = () => {
    searchParams.delete("item");
    setSearchParams(searchParams);
  };

  const handleDelete = () => {
    deleteDoc(doc(db, "items", props.itemId));
    handleClose();
  };

  let displayedKeys = ["brand", "condition", "size", "color", "category"];

  return (
    <>
      <div className="pt-5 flex flex-col gap-5">
        <Card>
          {data && (
            <>
              <CardHeader className="p-0">
                <Header fetch={fetch} itemId={props.itemId} data={data} />
              </CardHeader>
              <Divider />
              <CardBody>
                <>
                  <div className="mb-3 flex justify-between items-center">
                    <div className="flex items-center gap-2">
                      <h1 className="text-xl font-bold text-slate-300">
                        {data.title}
                      </h1>
                      <div className="cursor-pointer" onClick={handleEdit}>
                        <EditIcon size={20} color="#3F3F45" />
                      </div>
                    </div>
                    <div className="cursor-pointer" onClick={handleClose}>
                      <CrossIcon size={30} color="white" />
                    </div>
                  </div>
                  {data.images ? <Images imageList={data.images} /> : null}

                  <h1 className="pt-2 text-slate-300">{data.description}</h1>
                  <div className="pt-2 flex gap-1 overflow-x-scroll">
                    {displayedKeys.map((key) => (
                      <Chip key={key}>
                        {key}: {data[key].toString()}
                      </Chip>
                    ))}
                  </div>
                </>
              </CardBody>
              {data.status == "saved" && (
                <>
                  <Divider />
                  <CardFooter>
                    <Button onPress={handleDelete} color="danger">
                      Supprimer
                    </Button>
                  </CardFooter>
                </>
              )}
            </>
          )}
        </Card>
      </div>
    </>
  );
}
