import { Card } from "@nextui-org/card";
import { Button, Chip, Input } from "@nextui-org/react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { auth } from "../../firebase";
import { EyeSlashFilledIcon } from "../../icons/EyeSlashFilledIcon";
import { EyeIcon } from "../signup/EyeIcon";

const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const { dispatch } = useContext(AuthContext);

  const handleLogin = (e) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(user);
        dispatch({ type: "LOGIN", payload: user });
        navigate("/home");
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      });
  };

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <>
      <div className="text-4xl font-bold mb-8 text-center">
        Heureux de vous revoir!
      </div>
      <div className="w-full flex justify-center">
        <div className="w-[400px] flex flex-col gap-5 items-center">
          {error && (
            <Chip color="danger">Email ou mot de passe incorrects</Chip>
          )}

          <Input
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            label="Email"
            placeholder="Entrez votre email"
          />
          <Input
            label="Mot de passe"
            placeholder="Entrez votre mot de passe"
            onChange={(e) => setPassword(e.target.value)}
            endContent={
              <button
                onClick={toggleVisibility}
                aria-label="toggle password visibility"
              >
                {isVisible ? (
                  <EyeSlashFilledIcon className="text-2xl text-default-400" />
                ) : (
                  <EyeIcon className="text-2xl text-default-400" />
                )}
              </button>
            }
            type={isVisible ? "text" : "password"}
          />
          <Button
            isDisabled={!(email.length > 0 && password.length > 0)}
            color="primary"
            onPress={() => handleLogin()}
          >
            Se Connecter
          </Button>
        </div>
      </div>
      <p className="pt-10 text-slate-500 italic text-center">
        Vous ne possedez pas de compte?{" "}
        <Link to="/sign-up">
          <span className="underline">Creez-en un</span>
        </Link>
      </p>
    </>
  );
};

export default Login;
