import { Divider, Input, Button } from "@nextui-org/react";
import { useState } from "react";
import { EyeSlashFilledIcon } from "../../icons/EyeSlashFilledIcon";
import { EyeFilledIcon } from "../../icons/EyeFilledIcon";
import { auth } from "../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

export default function PasswordNSecurity() {
  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  const { currentUser } = useContext(AuthContext);

  const handlePasswordReset = () => {
    sendPasswordResetEmail(auth, currentUser.email)
      .then(() => {
        setSuccess("Email envoye");
      })
      .catch((error) => {
        const errorCode = error.code;
        setError(error.message);
        // ..
      });
  };

  return (
    <div className="p-5 flex flex-col gap-5">
      <h3 className="text-lg font-bold">Account Password</h3>
      <Button onPress={handlePasswordReset}>Reset password</Button>
      <Divider />
      <p>{success}</p>
      <p className="text-red-500">{error}</p>
    </div>
  );
}
