const TruckIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox="0 0 48 48"
  >
    <g id="Layer_2" data-name="Layer 2">
      <path
        id="invisible_box"
        fill="none"
        d="M0 0h48v48H0z"
        data-name="invisible box"
      ></path>
      <g id="Health_Icons" data-name="Health Icons">
        <path
          fill={props.color}
          d="M37.7 11.1a3 3 0 0 0-2.3-1.1h-1.2l.3-1.7a3.1 3.1 0 0 0-.6-2.3 3.2 3.2 0 0 0-2.2-1H7.8a2 2 0 0 0 0 4h22.5l-4 22.9a6.8 6.8 0 0 0-1 2.1h-4.6a7 7 0 0 0-13.4 0H6.2l.5-2.9a2 2 0 0 0-1.6-2.3 2 2 0 0 0-2.3 1.6L2 34.7a2.8 2.8 0 0 0 .7 2.3 2.8 2.8 0 0 0 2.1 1h2.5a7 7 0 0 0 13.4 0h4.6a7 7 0 0 0 13.4 0h2a3.2 3.2 0 0 0 3.1-2.7L46 22.5ZM14 39a3 3 0 0 1-3-3 3 3 0 0 1 6 0 3 3 0 0 1-3 3m19.5-25h1.3l5.9 8h-8.6ZM32 39a3 3 0 0 1-3-3 3 3 0 0 1 6 0 3 3 0 0 1-3 3m8-5h-1.3a7 7 0 0 0-6.7-5h-1.1l.5-3.1h9.9Z"
        ></path>
        <path
          fill={props.color}
          d="M4 15h10a2 2 0 0 0 0-4H4a2 2 0 0 0 0 4M15 19a2 2 0 0 0-2-2H5a2 2 0 0 0 0 4h8a2 2 0 0 0 2-2M6 23a2 2 0 0 0 0 4h6a2 2 0 0 0 0-4Z"
        ></path>
      </g>
    </g>
  </svg>
);

export default TruckIcon;
