import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Provider from "./providers/Provider";
import { BrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { AuthContextProvider } from "./context/AuthContext";
import { VintedItemContextProvider } from "./context/VintedItemContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

const firebaseConfig = {
  apiKey: "AIzaSyCZaWU3WYWQ20eBHUioz9pJ_KvkZe0lpTA",
  authDomain: "ft-r-45f38.firebaseapp.com",
  projectId: "ft-r-45f38",
  storageBucket: "ft-r-45f38.appspot.com",
  messagingSenderId: "428931621479",
  appId: "1:428931621479:web:edee86cec37c05f3a72ac2",
  measurementId: "G-6KLS78KSBQ",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <VintedItemContextProvider>
        <BrowserRouter>
          <Provider>
            <App />
          </Provider>
        </BrowserRouter>
      </VintedItemContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);
