import { Card } from "@nextui-org/card";
import { Button, Input, Divider, Chip } from "@nextui-org/react";
import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { auth, RecaptchaVerifier } from "../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  signInWithPhoneNumber,
  EmailAuthProvider,
  linkWithCredential,
  sendEmailVerification,
} from "firebase/auth";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./PhoneNumber.css";
import { EyeFilledIcon, EyeIcon } from "./EyeIcon";
import { EyeSlashIcon } from "./EyeSlashIcon";

const SignupPage = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmResult, setConfirmResult] = useState(null);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    phone: null,
    email: null,
    password: [],
  });
  const [isValid, setIsValid] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isSolved, setIsSolved] = useState(false);

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "sign-in-button", {
      size: "invisible",
      callback: (response) => {
        setIsSolved(true);
      },
    });
  }, []);

  const validateInput = (field) => {
    const newErrors = { ...errors };

    if (!field || field === "phone") {
      const phoneRegex = /^\+?[1-9]\d{1,14}$/;
      newErrors.phone = phoneRegex.test("+" + phoneNumber)
        ? null
        : "Format du numéro de téléphone invalide.";
    }

    if (!field || field === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      newErrors.email = emailRegex.test(email)
        ? null
        : "Format de l'adresse email invalide.";
    }

    if (!field || field === "password") {
      const passwordErrors = [];
      if (password.length < 6 || password.length > 24) {
        passwordErrors.push(
          "Le mot de passe doit contenir entre 6 et 24 caractères."
        );
      }
      if (!/[A-Z]/.test(password)) {
        passwordErrors.push(
          "Le mot de passe doit contenir au moins une lettre majuscule."
        );
      }
      if (!/[a-z]/.test(password)) {
        passwordErrors.push(
          "Le mot de passe doit contenir au moins une lettre minuscule."
        );
      }
      if (!/[0-9]/.test(password)) {
        passwordErrors.push(
          "Le mot de passe doit contenir au moins un chiffre."
        );
      }
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        passwordErrors.push(
          "Le mot de passe doit contenir au moins un caractère spécial."
        );
      }
      newErrors.password = passwordErrors.length ? passwordErrors : null;
    }

    if (field == "zero") {
      setErrors({
        phone: null,
        email: null,
        password: [],
      });
    } else {
      setErrors(newErrors);
    }

    setIsValid(
      !newErrors.phone &&
        !newErrors.email &&
        (!newErrors.password || newErrors.password.length === 0)
    );
  };

  useEffect(() => validateInput("phone"), [phoneNumber]);
  useEffect(() => validateInput("email"), [email]);
  useEffect(() => validateInput("password"), [password]);
  useEffect(() => validateInput("zero"), []);

  const handleSendCode = async () => {
    if (!isValid) return;
    const appVerifier = window.recaptchaVerifier;
    const phone = "+" + phoneNumber;
    try {
      signInWithPhoneNumber(auth, phone, appVerifier)
        .then((confirmationResult) => {
          setConfirmResult(confirmationResult);
          setIsCodeSent(true);
        })
        .catch((error) => {
          setErrors({ ...errors, phone: error.message });
        });
    } catch (error) {
      console.error("Error during sign in:", error);
      alert(error.message);
    }
  };

  const handleVerifyCode = async () => {
    if (!confirmResult) return;
    try {
      await confirmResult.confirm(verificationCode);
      await handleLinkEmailPassword();
      navigate("/home");
    } catch (error) {
      console.error("Error verifying code:", error);
      alert(error.message);
    }
  };

  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  const handleLinkEmailPassword = async () => {
    const user = auth.currentUser;
    if (!user) return alert("User not signed in!");

    try {
      const credential = EmailAuthProvider.credential(email, password);
      await linkWithCredential(user, credential);
      await sendEmailVerification(user);
      dispatch({ type: "LOGIN", payload: user });
      navigate("/home");
    } catch (error) {
      console.error("Error linking email and password:", error);
      alert(error.message);
    }
  };

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <>
      <div className="text-4xl font-bold mb-4 text-center">S'inscrire</div>
      <div>
        {!isCodeSent ? (
          <div className="w-full flex justify-center">
            <div className="max-w-[400px] flex flex-col gap-5 items-center">
              <div>
                <PhoneInput
                  containerClass="!w-auto !rounded-lg !z-20"
                  inputClass="!w-[400px] !bg-default-100 !border-none !rounded-lg !h-[56px] hover:!bg-default-200"
                  buttonClass="!bg-default-100 !rounded-lg !border-none"
                  dropdownClass="!bg-default-100"
                  placeholder="+33 1 23 45 67 89"
                  country={"fr"}
                  value={phoneNumber}
                  onChange={(phone) => setPhoneNumber(phone)}
                />
                {errors.phone && (
                  <Chip className="mt-2" size="sm" color="danger">
                    {errors.phone}
                  </Chip>
                )}
              </div>
              <div className="w-full">
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  label="Email"
                  placeholder="Entrez votre email"
                />
                {errors.email && (
                  <Chip className="mt-2" size="sm" color="danger">
                    {errors.email}
                  </Chip>
                )}
              </div>

              <div className="w-full">
                <Input
                  label="Mot de passe"
                  placeholder="Entrez votre mot de passe"
                  onChange={(e) => setPassword(e.target.value)}
                  endContent={
                    <button
                      onClick={toggleVisibility}
                      aria-label="toggle password visibility"
                    >
                      {isVisible ? (
                        <EyeSlashIcon className="text-2xl text-default-400" />
                      ) : (
                        <EyeIcon className="text-2xl text-default-400" />
                      )}
                    </button>
                  }
                  type={isVisible ? "text" : "password"}
                />
                {errors.password && (
                  <div>
                    {errors.password.map((error, index) => (
                      <Chip
                        key={index}
                        className="mt-2"
                        size="sm"
                        color="danger"
                      >
                        {error}
                      </Chip>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="mb-2 text-slate-500 text-small text-center">
              Un code vous a ete envoye au +{phoneNumber}
            </div>
            <Input
              type="text"
              placeholder="Code de verification"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
            />
          </div>
        )}
        <div className="mt-5 flex justify-center">
          <Button
            isDisabled={
              !(
                isValid &&
                email.length > 0 &&
                phoneNumber.length > 0 &&
                password.length > 0
              )
            }
            onPress={handleSendCode}
            style={isSolved ? { display: "none" } : { display: "block" }}
            id="sign-in-button"
            color="primary"
          >
            S'inscrire
          </Button>
          <Button
            isDisabled={
              !(
                isValid &&
                email.length > 0 &&
                phoneNumber.length > 0 &&
                password.length > 0
              )
            }
            style={!isSolved ? { display: "none" } : { display: "block" }}
            onPress={isCodeSent ? handleVerifyCode : handleSendCode}
            color="primary"
          >
            {isCodeSent ? "Verifier le code" : "S'inscrire"}
          </Button>
        </div>
      </div>
      {!isCodeSent && (
        <p className="pt-10 text-slate-500 italic text-center">
          Vous possedez deja un compte?{" "}
          <Link to="/login">
            <span className="underline">Connectez-vous</span>
          </Link>
        </p>
      )}
    </>
  );
};

export default SignupPage;
