import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import { useEffect, useState } from "react";
import ArrowLeft from "../../icons/ArrowLeft";
import ArrowRight from "../../icons/ArrowRight";

const Images = ({ imageList }) => {
  const [modalIndex, setModalIndex] = useState(0);

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <div className="grid grid-cols-4 gap-3">
      <div className="aspect-square col-span-2 row-span-2">
        <img
          onClick={onOpen}
          className="aspect-square rounded-md h-full bg-slate-500 object-cover"
          src={imageList[0]}
        />
      </div>
      {imageList.slice(1, 4).map((image, i) => (
        <img
          onClick={onOpen}
          key={i}
          className="aspect-square rounded-md h-full bg-slate-500 object-cover"
          src={image}
        />
      ))}
      {imageList.length > 4 ? (
        <Card isPressable onPress={onOpen}>
          <div className="w-full h-full flex justify-center items-center">
            Plus...
          </div>
        </Card>
      ) : null}

      <>
        <Modal
          backdrop="blur"
          classNames={{ base: "bg-[#18181B]" }}
          size="2xl"
          isOpen={isOpen}
          onOpenChange={onOpenChange}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalBody>
                  <div className="grid grid-cols-3 grid-cols-[50px_1fr_50px] py-5">
                    <div
                      onClick={() => {
                        if (modalIndex > 0) {
                          setModalIndex((index) => index - 1);
                        }
                      }}
                      className={`flex items-center ${
                        modalIndex < 1 ? "opacity-30" : null
                      }`}
                    >
                      <ArrowLeft size="50" />
                    </div>
                    <img
                      className="aspect-square rounded-md bg-slate-500 object-cover"
                      src={imageList[modalIndex]}
                    />
                    <div
                      onClick={() => {
                        if (modalIndex < imageList.length - 1) {
                          setModalIndex((index) => index + 1);
                        }
                      }}
                      className={`flex items-center ${
                        modalIndex == imageList.length - 1 ? "opacity-30" : null
                      }`}
                    >
                      <ArrowRight size="50" />
                    </div>
                  </div>
                  <div className="flex w-full gap-1 overflow-x-scroll h-[80px]">
                    {imageList.map((image, i) => (
                      <img
                        onClick={() => setModalIndex(i)}
                        key={i}
                        style={
                          modalIndex == i
                            ? { opacity: "1" }
                            : { opacity: "0.5" }
                        }
                        className="aspect-square rounded-md bg-slate-500 object-cover"
                        src={image}
                      />
                    ))}
                  </div>
                </ModalBody>
              </>
            )}
          </ModalContent>
        </Modal>
      </>
    </div>
  );
};

export default Images;
