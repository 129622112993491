import {
  Avatar,
  Card,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { getAuth } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext.js";
import { AcmeLogo } from "../../icons/AcmeLogo.js";
import { app } from "../../pages/firebase.config.js";
import { doc, getDoc } from "@firebase/firestore";
import { db } from "../../firebase.js";
import UserIcon from "../../icons/UserIcon.js";
import HomeIcon from "../../icons/HomeIcon.js";
import WalletIcon from "../../icons/WalletIcon.js";
import GraphIcon from "../../icons/GraphIcon.js";

export default function NavBar() {
  const { dispatch } = useContext(AuthContext);

  function handleLogout() {
    const auth = getAuth(app);
    auth.signOut();
    dispatch({ type: "LOGOUT", payload: "" });
  }

  const [data, setData] = useState({});

  const { currentUser } = useContext(AuthContext);

  const fetch = async () => {
    try {
      const docRef = doc(db, "users", currentUser.uid);
      const docSnap = await getDoc(docRef);
      const docData = docSnap.data();
      docData && setData(docData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="w-[80px] bg border-[#52525B] h-full drop-shadow py-5">
      <div className="h-full grid grid-row-3">
        <div className="w-full flex items-start justify-center">
          <AcmeLogo />
        </div>
        <div className="h-full border-gray-600 flex flex-col items-between gap-px">
          <NavLink
            to="/home"
            className={({ isActive }) =>
              isActive
                ? "opacity-1 grow flex grow justify-center items-center"
                : "opacity-30 grow flex grow justify-center items-center"
            }
          >
            <HomeIcon size={30} color="white" />
          </NavLink>
          <NavLink
            to="/wallet"
            className={({ isActive }) =>
              isActive
                ? "opacity-1 grow flex grow justify-center items-center"
                : "opacity-30 grow flex grow justify-center items-center"
            }
          >
            <WalletIcon size={30} color="white" />
          </NavLink>
          <NavLink
            to="/stats"
            className={({ isActive }) =>
              isActive
                ? "opacity-1 grow flex grow justify-center items-center"
                : "opacity-30 grow flex grow justify-center items-center"
            }
          >
            <GraphIcon size={27} color="white" />
          </NavLink>
        </div>
        <div className="h-full flex flex-col items-center justify-center sm:justify-end gap-5">
          <Link className="flex gap-2" to="/wallet">
            <Card className="p-2">400€</Card>
          </Link>
          <div>
            <Dropdown
              className="bg-[#18181B] text-white"
              closeOnSelect={false}
              placement="top-end"
            >
              <DropdownTrigger>
                {data.img ? (
                  <Avatar
                    isBordered
                    as="button"
                    className="transition-transform"
                    color="secondary"
                    name="Jason Hughes"
                    size="sm"
                    src={data.img}
                  />
                ) : (
                  <div className="cursor-pointer border-3 p-1 rounded-full border-secondary">
                    <UserIcon size={25} color="white" />
                  </div>
                )}
              </DropdownTrigger>
              <DropdownMenu aria-label="Profile Actions" variant="flat">
                <DropdownItem
                  closeOnSelect
                  href="/"
                  key="profile"
                  className="h-14 gap-2"
                  color="primary"
                >
                  <p className="font-semibold">Signed in as</p>
                  <p className="font-semibold">{currentUser.email}</p>
                </DropdownItem>
                <DropdownItem closeOnSelect key="settings" href="/settings">
                  My Settings
                </DropdownItem>
                <DropdownItem closeOnSelect key="wallet">
                  My Wallet
                </DropdownItem>
                <DropdownItem closeOnSelect key="help_and_feedback">
                  Help & Feedback
                </DropdownItem>
                <DropdownItem closeOnSelect key="credits">
                  Credits
                </DropdownItem>
                <DropdownItem closeOnSelect key="terms_and_conditions">
                  Terms & Conditions
                </DropdownItem>
                <DropdownItem
                  closeOnSelect
                  key="logout"
                  color="danger"
                  onClick={handleLogout}
                >
                  Log Out
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}
