import { Card } from "@nextui-org/card";

const WalletPage = () => {
  return (
    <div className="pt-5 flex flex-col gap-5">
      <div className="grid grid-cols-2 gap-5">
        <Card>
          <div className="p-5">$500</div>
        </Card>
        <Card>
          <div className="p-5">Cashout</div>
        </Card>
      </div>

      <Card>
        <div className="p-5">Analytics</div>
      </Card>
    </div>
  );
};

export default WalletPage;
