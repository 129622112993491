import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const ItemCard = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  function openSelected() {
    let item;
    if (searchParams.has("item")) {
      item = searchParams.getAll("item");
      searchParams.delete("item");
    }
    searchParams.append("item", props.data.id);

    setSearchParams(searchParams);
  }

  const [status, setStatus] = useState("");

  useEffect(() => {
    switch (props.data.status) {
      case "saved":
        return setStatus("Sauvegarde");
      case "negotiating":
        return setStatus("En cours de negociation");
      case "confirmBuy":
        return setStatus("Confirmer l'achat");
      case "receiving":
        return setStatus("En attente d'etre recu");
      case "handling":
        return setStatus("En cours de traitement");
      case "selling":
        return setStatus("En cours de vente");
      case "sent":
        return setStatus("Vendu (envoye)");
      case "sold":
        return setStatus("Vendu");
    }
  }, [props.data]);

  return (
    <>
      <Card
        onPress={openSelected}
        isPressable
        className="w-full aspect-square col-span-12 sm:col-span-5"
      >
        <CardHeader className="absolute z-10 top-1 flex-col items-start">
          <h4
            style={{
              textShadow:
                "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
            }}
            className="text-black text-white font-medium text-2xl text-left"
          >
            {props.data.title}
          </h4>
        </CardHeader>
        <Image
          removeWrapper
          alt="Card example background"
          className="z-0 w-full h-full scale-125 -translate-y-6 object-cover"
          src={
            props.data.images
              ? props.data.images[0]
              : "https://nextui.org/images/hero-card.jpeg"
          }
        />
        <CardFooter className="absolute bottom-0 z-10 flex flex-col items-end gap-2 justify-end">
          <Chip>
            <span className="text-slate-400">{status}</span>
          </Chip>
          <Chip className="flex w-full items-center justify-end">
            <span className="text-slate-400">Benefices: </span>
            <span className="text-green-500 font-bold">
              {props.data.profit ? (
                props.data.profit
              ) : (
                <>
                  {props.data.sellTargetMin - props.data.buyTargetMax}-
                  {props.data.sellTargetMax - props.data.buyTargetMin}€
                </>
              )}
            </span>
          </Chip>
        </CardFooter>
      </Card>
    </>
  );
};

export default ItemCard;
