import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import NavBar from "../navbar/NavBar";
import SelectedItem from "../selectedItem/SelectedItem";
import { VintedItemContext } from "../../context/VintedItemContext";
import EditItem from "../selectedItem/EditItem";
import { Card } from "@nextui-org/react";
import { collection, onSnapshot, query, where } from "@firebase/firestore";
import { db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import List from "../../components/list/List";
import EmptyIcon from "../../icons/EmptyIcon";

export default function Layout(props) {
  const [selectedItem, setSelectedItem] = useState();
  const [selectedEditItem, setSelectedEditItem] = useState();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { vintedItem } = useContext(VintedItemContext);

  useEffect(() => {
    if (searchParams.has("item")) {
      setSelectedItem(searchParams.getAll("item")[0]);
    } else {
      setSelectedItem(0);
    }
    if (searchParams.has("editItem")) {
      setSelectedEditItem(searchParams.getAll("editItem")[0]);
    } else {
      setSelectedEditItem(0);
    }
  }, [location]);

  useEffect(() => {}, [selectedItem]);

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const unsub = onSnapshot(
      query(
        collection(db, "items"),
        where("owner", "==", currentUser.uid),
        where("status", "==", "saved")
      ),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setItems([...list]);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  return (
    <div
      className={
        "w-screen h-screen overflow-hidden grid grid-cols-3 grid-cols-[80px_1fr_1fr]"
      }
    >
      <div className="h-full z-20">
        <NavBar />
      </div>
      <>
        {selectedItem || selectedEditItem || vintedItem ? (
          <div className="pb-5 overflow-y-scroll">
            {vintedItem ? (
              <EditItem vintedItem />
            ) : selectedItem ? (
              <SelectedItem itemId={selectedItem} />
            ) : (
              <EditItem itemId={selectedEditItem} />
            )}
          </div>
        ) : (
          <div className="py-5 pl-5 flex flex-col gap-5">
            <Card className="p-5">
              <span className="text-xl font-bold mb-5">
                Prets a etre revendus
              </span>
              <List
                isLoading={isLoading}
                data={items}
                emptyMessage={
                  <div className="flex flex-col items-center">
                    <EmptyIcon size={30} color="#94A3B8" />
                    <span className="flex text-slate-400 items-center gap-2 mb-2">
                      Commencez par importer un article Vinted
                    </span>
                  </div>
                }
              />
            </Card>
            <Card className="p-5">
              <span className="mb-5 tracking-tight inline font-semibold from-[#FF1CF7] to-[#b249f8] text-[1.5rem] bg-clip-text text-transparent bg-gradient-to-b">
                Offre de lancement: vendez gratuitement
              </span>
              <List isLoading={isLoading} data={items} />
            </Card>
          </div>
        )}
        <div
          className={`max-h-full h-full overflow-y-scroll sm:col-span-1 col-span-3 px-5`}
        >
          <Outlet />
        </div>
      </>
    </div>
  );
}
