const HomeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M21.45 10.275 12 3.188l-9.45 7.087.45 1.35h.75v8.625h16.5v-8.625H21zM5.25 18.75v-8.625L12 5.063l6.75 5.062v8.625H15v-4.417l-.75-.75h-4.5l-.75.75v4.417zm5.25 0h3v-3.667h-3z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default HomeIcon;
