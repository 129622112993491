const WalletIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="m4.5 6 .75-.75H18l.75.75v1.5h.75l.75.75v10.5l-.75.75H5.25l-.75-.75V6m14.25 9.75h-3a2.25 2.25 0 0 1 0-4.5h3V9H6v9h12.75zm-1.5-9v.75H6v-.75zM15 13.5a.75.75 0 0 1 .75-.75h3v1.5h-3a.75.75 0 0 1-.75-.75"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default WalletIcon;
