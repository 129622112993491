import { Outlet } from "react-router";
import { motion } from "framer-motion";
import { Card, Chip } from "@nextui-org/react";
import ItemCard from "../../components/card/ItemCard";
import vintedScreenshot from "../../icons/VintedScreenshot.png";
import TickIcon from "../../icons/TickIcon";

const itemData = {
  id: "AJwqTkkav6HrwHK0pXci",
  sellTargetMin: 140,
  sellTargetMax: 150,
  buyTargetMin: 120,
  buyTargetMax: 120,
  title: "Pull Stone Island Blanc",
  brand: "Stone Island",
  owner: "1DqlJiRNTDQNFm5pcl9yFOu5CUv2",
  category: "Sweaters",
  color: "White",
  declaredDate: {
    seconds: 1729691225,
    nanoseconds: 135000000,
  },
  status: "saved",
  images: [
    "https://images1.vinted.net/tc/02_01dfd_iWLrrqZwkaCsFbuqJWYGkqcY/1729093891.jpeg?s=4df91b7d1ced8c70933f0832f2c7a84c6caca4fe",
    "https://images1.vinted.net/tc/02_00375_vbrrm3xPdABGghgbHwk6PyvK/1729093891.jpeg?s=1cf8a0e81053feb7ae121a6f85fc42240d356430",
    "https://images1.vinted.net/tc/03_00207_D2wDUbZT2GJ7MMqCDC6FKLu2/1729093891.jpeg?s=e02bd17a88348291617b548a5255cd16eb2d12a2",
    "https://images1.vinted.net/tc/03_015ce_k6N2Q1idBKrbFN3Nzu6fvw3X/1729093891.jpeg?s=33676527b2d0797d12323647e46f09633bf37db7",
    "https://images1.vinted.net/tc/02_001cd_WBTeQUcnoYBez5B8UFr9AbUK/1729093891.jpeg?s=279bbcdec7f2021dd50327d7cd0653162f14de0f",
    "https://images1.vinted.net/tc/02_018a1_CodbC251Nk8RjdgvQdCsCoue/1729093891.jpeg?s=8e06c8bbb8b2937061c97102521790e9efa8c8e5",
    "https://images1.vinted.net/tc/03_0017d_iTBpaQ9opPy7MdkzJngZp9nn/1729093891.jpeg?s=1085179eb7675d1392b456da76b9cccddcfba884",
    "https://images1.vinted.net/tc/03_00bdc_ZwCL5mHUqyUynW4QUTtEJ1GN/1729093891.jpeg?s=cc3d4af312af88b06c418c82be7a96be048dc63f",
    "https://images1.vinted.net/tc/02_01a05_yPCMTWTkaLkcRwB883sXzgNC/1729093891.jpeg?s=8656a00c6e11c969ae34950d6127be11adf9d580",
  ],
  vintedUrl: "https://www.vinted.com/items/5228336600-pull-stone-island-blanc",
  size: "M",
  description:
    "article porté quelques fois présente quelques défauts (voir photos)\n100 % authentique ✅✅\nN’hésitez pas à me contacter pour plus de renseignements",
  condition: "Good",
  price: "109.99",
  profit: "20",
};

const AuthLayout = () => {
  return (
    <div className="pl-10 mt-[-70px] h-screen grid grid-cols-2 grid-cols-[50%_50%] flex items-center overflow-y-scroll">
      <div className="grid grid-cols-2 border-r-2 border-[#18181B] pr-10">
        <div className="">
          <motion.div
            animate={{ y: 20 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 3,
              ease: "easeInOut",
              delay: 0.5,
            }}
          >
            <img className="max-w-[400px] rounded-md" src={vintedScreenshot} />
          </motion.div>

          <motion.div
            animate={{ y: 20 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 3,
              ease: "easeInOut",
              delay: 0.5,
            }}
            className="pt-5 pl-5 flex flex-col gap-2"
          >
            <Chip
              startContent={<TickIcon color="#0966D9" size={20} />}
              className="ml-5"
            >
              Experts en negotiacions
            </Chip>
            <Chip startContent={<TickIcon color="#0966D9" size={20} />}>
              Photos professionnels
            </Chip>
            <Chip
              startContent={<TickIcon color="#0966D9" size={20} />}
              className="ml-5"
            >
              Stockage
            </Chip>
            <Chip
              startContent={<TickIcon color="#0966D9" size={20} />}
              className="ml-10"
            >
              Livraison
            </Chip>
          </motion.div>
        </div>
        <motion.div
          animate={{ y: -30 }}
          transition={{
            repeat: Infinity,
            repeatType: "reverse",
            duration: 3,
            ease: "easeInOut",
            delay: 1,
          }}
          className="pt-[60px] max-w-[400px] flex flex-col gap-2 items-center"
        >
          <ItemCard type="selling" data={itemData} />
        </motion.div>
        <motion.div
          animate={{ y: 30 }}
          transition={{
            repeat: Infinity,
            repeatType: "reverse",
            duration: 3,
            ease: "easeInOut",
            delay: 1.5,
          }}
          className="mt-[-30px] mr-[-50px] ml-[50px] z-20"
        >
          <Card>
            <div className="p-5">Benefices: $320</div>
          </Card>
        </motion.div>
      </div>
      <div className="px-10">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
