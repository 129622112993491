import { initializeApp } from "firebase/app";
import { RecaptchaVerifier, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCZaWU3WYWQ20eBHUioz9pJ_KvkZe0lpTA",
  authDomain: "ft-r-45f38.firebaseapp.com",
  projectId: "ft-r-45f38",
  storageBucket: "ft-r-45f38.appspot.com",
  messagingSenderId: "428931621479",
  appId: "1:428931621479:web:edee86cec37c05f3a72ac2",
  measurementId: "G-6KLS78KSBQ",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
export { RecaptchaVerifier };
