import { doc, getDoc, serverTimestamp, updateDoc } from "@firebase/firestore";
import { Button, Divider, Input, Link, Spinner, User } from "@nextui-org/react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db, storage } from "../../firebase";
import PhoneInput from "react-phone-input-2";
import "../signup/PhoneNumber.css";

export default function AccountInfo() {
  const { currentUser } = useContext(AuthContext);

  const [email, setEmail] = useState(currentUser.email);
  const [phoneNumber, setPhoneNumber] = useState(currentUser.phoneNumber);

  return (
    <div className="p-5 flex flex-col gap-5">
      <h3 className="text-lg font-bold">Account Identification</h3>
      <Input
        placeholder="Enter the email you wish to use"
        type="email"
        label="Email"
        value={email}
        onValueChange={setEmail}
      />
      <PhoneInput
        containerClass="!w-auto !rounded-lg"
        inputClass="!w-full !bg-default-100 !border-none !rounded-lg !h-[56px] hover:!bg-default-200"
        buttonClass="!bg-default-100 !rounded-lg !border-none"
        dropdownClass="!z-20 !bg-default-100"
        country={"fr"}
        value={phoneNumber}
        onChange={(phone) => setPhoneNumber(phone)}
      />
      <Button className="mt-2 self-end" color="primary" onPress={() => {}}>
        Save
      </Button>
    </div>
  );
}
