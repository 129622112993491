const WriteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    width={props.size}
    height={props.size}
    fill={props.color}
    version="1.1"
    viewBox="0 0 512 512"
  >
    <path d="M0 439.5h170.7V268.8H0zm42.7-128H128v85.3H42.7zm170.6 106.7H512v-42.7H213.3zM0 226.2h170.7V55.5H0zm42.7-128H128v85.3H42.7zm170.6-21.4v42.7H512V76.8zm0 256H512v-42.7H213.3zm0-128H512v-42.7H213.3z"></path>
  </svg>
);

export default WriteIcon;
