import { Card, CardFooter } from "@nextui-org/card";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
} from "@nextui-org/react";
import React, { useState } from "react";

const testData = [
  {
    id: 1234,
    title: "Clothing Name Jacket size XL and this goes on",
    description: "I want to sell this",
    price: 1000,
    status: "stored",
    declaredDate: "",
    storedDate: "",
  },
  {
    id: 1234,
    title: "Clothing Name",
    description: "I want to sell this",
    price: 1000,
    status: "stored",
    declaredDate: "",
    storedDate: "",
  },
  {
    id: 1234,
    title: "Clothing Name",
    description: "I want to sell this",
    price: 1000,
    status: "stored",
    declaredDate: "",
    storedDate: "",
  },
  {
    id: 1234,
    title: "Clothing Name",
    description: "I want to sell this",
    price: 1000,
    status: "stored",
    declaredDate: "",
    storedDate: "",
  },
];

export default function WhatYouSell() {
  const [isLoading, setLoading] = useState(false);

  return (
    <div className="p-5 flex flex-col gap-5">
      <Input
        variant="underlined"
        placeholder="Search what you're currently selling on your store"
      />
      <div className="gap-2 grid grid-cols-2">
        {!isLoading ? (
          testData.map((item) => (
            <>
              <Card isFooterBlurred radius="lg" className="border-none">
                <Image
                  isZoomed
                  alt="Woman listing tdo music"
                  className="object-cover"
                  src="https://nextui.org/images/hero-card.jpeg"
                />
                <CardFooter className="justify-between before:bg-white/10 border-white/20 border-1 overflow-hidden py-1 absolute before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">
                  <Popover placement="top-start">
                    <PopoverTrigger>
                      <Button className="bg-transparent justify-start p-0">
                        {item.title}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <div className="px-1 py-2">
                        <div className="text-small font-bold">{item.title}</div>
                        <div className="text-tiny">{item.description}</div>
                      </div>
                    </PopoverContent>
                  </Popover>
                  <Dropdown>
                    <DropdownTrigger>
                      <Button color="primary" variant="solid">
                        Gerer
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Static Actions">
                      <DropdownItem color="primary" key="edit">
                        Envoyer
                      </DropdownItem>
                      <DropdownItem key="copy">Retirer</DropdownItem>
                      <DropdownItem key="new">Modifier</DropdownItem>
                      <DropdownItem
                        key="delete"
                        className="text-danger"
                        color="danger"
                      >
                        Supprimer
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </CardFooter>
              </Card>
            </>
          ))
        ) : (
          <div className="items-center">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}
