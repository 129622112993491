import { useState } from "react";

export default function AccountPrivacy() {
  const [isSelected, setIsSelected] = useState(true);

  return (
    <div className="p-5 flex flex-col gap-2">
      <h2>Account Privacy</h2>
      <p>Default currency: </p>
    </div>
  );
}
