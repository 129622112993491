import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Chip,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
  Divider,
  CardFooter,
  Tab,
  Tabs,
} from "@nextui-org/react";
import { db } from "../../../firebase";
import { deleteDoc, doc } from "@firebase/firestore";
import { useNavigate } from "react-router";
import EditIcon from "../../../icons/EditIcon";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import Images from "../../../components/images/Images";
import { useEffect } from "react";
import TickIcon from "../../../icons/TickIcon";

export const Header = (props) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  console.log(props.data);
  const [isSelling, setIsSelling] = useState(props.data.status != "saved");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setIsSelling(props.data.status != "saved");
  }, [props.data]);

  const handleEdit = () => {
    searchParams.delete("item");
    searchParams.append("editItem", props.itemId);
    setSearchParams(searchParams);
  };

  const functions = getFunctions();
  const handleStartProccess = async () => {
    const markAsNegotiating = httpsCallable(functions, "startProccess");

    try {
      const result = await markAsNegotiating({ itemId: props.itemId });
      props.fetch();
      console.log(result.data); // "Item status updated to negotiating."
      setIsSelling(true);
    } catch (error) {
      console.error("Error marking item as negotiating:", error);
    }
  };

  return (
    <div className="w-full">
      <div className="w-full grid grid-cols-3 gap-5 p-5">
        <div className="flex gap-5 items-center justify-between">
          <span className="w-full flex flex-col">
            <div className="flex justify-between">
              <span className="text-slate-500">
                {props.data.buyPrice ? "Prix d'achat" : "Objectif d'achat"}
              </span>
              {props.data.status == "saved" && (
                <div className="cursor-pointer" onClick={handleEdit}>
                  <EditIcon size={20} color="#3F3F45" />
                </div>
              )}
            </div>
            <span className="text-3xl font-bold">
              {props.data.buyPrice ? (
                <>{props.data.buyPrice}€</>
              ) : (
                <>
                  {props.data.buyTargetMin}-{props.data.buyTargetMax}€
                </>
              )}
            </span>
          </span>
          <Divider orientation="vertical" />
        </div>
        <div className="flex gap-5 items-center justify-between">
          <span className="w-full flex flex-col">
            <div className="flex justify-between">
              <span className="text-slate-500">
                {props.data.sellPrice ? "Prix de vente" : "Objectif de vente"}
              </span>
              {props.data.status == "saved" && (
                <div className="cursor-pointer" onClick={handleEdit}>
                  <EditIcon size={20} color="#3F3F45" />
                </div>
              )}
            </div>
            <span className="text-3xl font-bold">
              {props.data.sellPrice ? (
                <>{props.data.sellPrice}€</>
              ) : (
                <>
                  {props.data.sellTargetMin}-{props.data.sellTargetMax}€
                </>
              )}
            </span>
          </span>
          <Divider orientation="vertical" />
        </div>

        <div className="flex gap-5 items-center justify-between">
          <span className="w-full flex flex-col">
            <div className="flex justify-between text-slate-500">Benefices</div>
            <span className="text-green-500 text-3xl font-bold">
              {props.data.profit ? (
                <>{props.data.profit}€</>
              ) : (
                <>
                  {props.data.sellTargetMin - props.data.buyTargetMax}-
                  {props.data.sellTargetMax - props.data.buyTargetMin}€
                </>
              )}
            </span>
          </span>
        </div>
      </div>
      <Divider />
      {isSelling ? (
        <div className="p-2 flex flex-col w-full">
          <Tabs
            size="lg"
            classNames={{
              base: "z-20 grow overflow-x-scroll",
              tabList: "!bg-[#18181B]",
            }}
            aria-label="Options"
            variant="underlined"
          >
            <Tab
              isDisabled={props.data.status == "saved"}
              key="Achat"
              title={
                <div className="flex items-center space-x-2">
                  <span>Achat</span>
                </div>
              }
            >
              <div className="border-2 border-[#3F3F45] rounded-lg p-5 flex justify-between items-end">
                <div className="flex flex-col gap-2">
                  <Chip className="text-slate-400">
                    Prix sur l'annonce:{" "}
                    <span className="font-bold">{props.data.price}</span>€
                  </Chip>
                  <Chip className="text-slate-400">
                    Prix a negocier:{" "}
                    <span className="font-bold">
                      {props.data.buyTargetMin}-{props.data.buyTargetMax}€
                    </span>
                  </Chip>
                  {props.data.buyPrice && (
                    <Chip color="primary">
                      Prix d'achat:{" "}
                      <span className="font-bold text-white">
                        {props.data.buyPrice}€
                      </span>
                    </Chip>
                  )}
                </div>
                <TickIcon size={40} color="#016FEE" />
              </div>
            </Tab>
            <Tab
              isDisabled={
                props.data.status == "negotiating" ||
                props.data.status == "confirmBuy" ||
                props.data.status == "saved"
              }
              key="Gestion"
              title={
                <div className="flex items-center space-x-2">
                  <span>Gestion</span>
                </div>
              }
            >
              <div className="border-2 border-[#3F3F45] rounded-lg p-5 flex justify-between items-end">
                <div className="flex flex-col">
                  <span className="text-slate-500">En cours de livraison</span>
                  <span className="text-slate-500">
                    Traquer: <span className="font-bold text-white">lien</span>
                  </span>
                </div>
              </div>
            </Tab>
            <Tab
              isDisabled={
                props.data.status == "receiving" ||
                props.data.status == "handling" ||
                props.data.status == "negotiating" ||
                props.data.status == "confirmBuy" ||
                props.data.status == "saved"
              }
              key="Revente"
              title={
                <div className="flex items-center space-x-2">
                  <span>Revente</span>
                </div>
              }
            >
              <div className="border-2 border-[#3F3F45] rounded-lg p-5 flex justify-between items-end">
                <div className="flex flex-col">
                  <span className="text-slate-500">
                    Prix d'achat: {props.data.buyTargetMax}€
                  </span>
                  <span className="text-slate-500">
                    Meilleur prix de vente:{" "}
                    <span className="font-bold text-white">
                      {props.data.sellTargetMin}€
                    </span>
                  </span>
                  {props.data.sellPrice && (
                    <span className="text-slate-500">
                      Prix de vente:{" "}
                      <span className="font-bold text-white">
                        {props.data.sellPrice}€
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </Tab>
            <Tab
              isDisabled={props.data.status != "sold"}
              key="Benefices"
              title={
                <div className="flex items-center space-x-2">
                  <span>Benefices</span>
                </div>
              }
            >
              <div className="border-2 border-[#3F3F45] rounded-lg p-5 flex justify-between items-end">
                <div className="flex flex-col">
                  <span className="text-slate-500">
                    Prix d'achat: {props.data.buyPrice}€
                  </span>
                  <span className="text-slate-500">
                    Prix de vente: {props.data.sellPrice}€
                  </span>
                  <span className="text-slate-500">Frais: 0€</span>
                  <span className="text-slate-500">
                    Vos gains:{" "}
                    <span className="font-bold text-white">
                      {props.data.profit}€
                    </span>
                  </span>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      ) : (
        <div className="p-5 w-full flex justify-between items-center">
          <span>
            Acheter & Revendre{" "}
            <span className="text-slate-500">
              (solde avant vente: 309€) (solde apres vente: 420-440€)
            </span>
          </span>
          <Button onPress={onOpen} color="primary">
            C'est parti
          </Button>
        </div>
      )}
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {props.data.title}
              </ModalHeader>
              <ModalBody>
                {props.data.images ? (
                  <Images imageList={props.data.images} />
                ) : null}
                <p>Prix sur l'anonce: ${props.data.price}</p>
                <Input
                  isRequired
                  label="Objectif d'achat"
                  value={props.data.buyTargetMin}
                />
                <Input
                  label="Prix d'achat max"
                  value={props.data.buyTargetMax}
                />

                <p className="text-tiny">
                  Nos experts vont negocier le prix auquel vous desirez acheter
                  l'article (Objectif d'achat) avec ou sans marge d'erreur (Prix
                  d'achat max)
                </p>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="primary"
                  onPress={() => {
                    handleStartProccess();
                    onClose();
                  }}
                >
                  Negocier
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export const Footer = (props) => {
  const functions = getFunctions();
  const [status, setStatus] = useState(props.data.status);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => {
    searchParams.delete("item");
    setSearchParams(searchParams);
  };

  const handleCancelNegotiation = async () => {
    const markAsNegotiating = httpsCallable(functions, "cancelNegotiation");

    try {
      const result = await markAsNegotiating({ itemId: props.itemId });
      props.fetch();
      console.log(result.data); // "Item status updated to negotiating."
    } catch (error) {
      console.error("Error marking item as negotiating:", error);
    }
  };

  const handleDelete = () => {
    deleteDoc(doc(db, "items", props.itemId));
    handleClose();
  };

  useEffect(() => {
    setStatus(props.data.status);
  }, [props.data]);

  return (
    <div className="w-full flex justify-end">
      {status == "saved" ? (
        <Button onPress={handleDelete} color="danger">
          Supprimer
        </Button>
      ) : status == "negotiating" ? (
        <Button onPress={handleCancelNegotiation} color="danger">
          Annuler
        </Button>
      ) : null}
    </div>
  );
};
