import { Divider } from "@nextui-org/divider";
import { Outlet } from "react-router";
import { NavLink } from "react-router-dom";

const ServicesLayout = () => {
  return (
    <div className="h-full grid grid-cols-2 grid-cols-[33%_67%]">
      <div className="flex w-full">
        <div className="w-full h-full justify-center flex flex-col items-end">
          <NavLink
            to="/welcome/nos-services/negociations"
            className={({ isActive }) =>
              isActive ? "font-bold py-5 pr-10" : "text-slate-400 py-5 pr-10"
            }
          >
            Negociations
          </NavLink>
          <NavLink
            to="/welcome/nos-services/stockage"
            className={({ isActive }) =>
              isActive ? "font-bold py-5 pr-10" : "text-slate-400 py-5 pr-10"
            }
          >
            Stockage
          </NavLink>
          <NavLink
            to="/welcome/nos-services/livraison"
            className={({ isActive }) =>
              isActive ? "font-bold py-5 pr-10" : "text-slate-400 py-5 pr-10"
            }
          >
            Livraison
          </NavLink>
          <NavLink
            to="/welcome/nos-services/gestion-argent"
            className={({ isActive }) =>
              isActive ? "font-bold py-5 pr-10" : "text-slate-400 py-5 pr-10"
            }
          >
            Gestion d'argent
          </NavLink>
        </div>
        <div className="w-[2px] self-center h-[50%] bg-[#18181B]" />
      </div>

      <Outlet />
    </div>
  );
};

export default ServicesLayout;
