// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyCZaWU3WYWQ20eBHUioz9pJ_KvkZe0lpTA",
	authDomain: "ft-r-45f38.firebaseapp.com",
	projectId: "ft-r-45f38",
	storageBucket: "ft-r-45f38.appspot.com",
	messagingSenderId: "428931621479",
	appId: "1:428931621479:web:edee86cec37c05f3a72ac2",
	measurementId: "G-6KLS78KSBQ",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
