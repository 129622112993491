import { doc, getDoc } from "@firebase/firestore";
import { Card } from "@nextui-org/card";
import { Button, User } from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase";

const stores = [
  {
    key: "new",
    label: "Store 1",
  },
  {
    key: "copy",
    label: "Store 2",
  },
  {
    key: "edit",
    label: "Store 3",
  },
  {
    key: "delete",
    label: "Store 4",
  },
];

const testData = [
  {
    id: 1234,
    title: "Clothing Name Jacket size XL and this goes on",
    description: "I want to sell this",
    price: 1000,
    status: "stored",
    declaredDate: "",
    storedDate: "",
  },
  {
    id: 1234,
    title: "Clothing Name",
    description: "I want to sell this",
    price: 1000,
    status: "stored",
    declaredDate: "",
    storedDate: "",
  },
  {
    id: 1234,
    title: "Clothing Name",
    description: "I want to sell this",
    price: 1000,
    status: "stored",
    declaredDate: "",
    storedDate: "",
  },
  {
    id: 1234,
    title: "Clothing Name",
    description: "I want to sell this",
    price: 1000,
    status: "stored",
    declaredDate: "",
    storedDate: "",
  },
];

export default function ProfileStore() {
  const [data, setData] = useState({});

  const { currentUser } = useContext(AuthContext);

  const fetch = async () => {
    try {
      const docRef = doc(db, "users", currentUser.uid);
      const docSnap = await getDoc(docRef);
      setData(docSnap.data());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="pt-5">
      <Card className="p-5 flex flex-col items-start gap-2">
        <div className="flex w-full justify-between">
          <User
            name={data.displayName}
            description={data.username}
            avatarProps={{
              src: data.img,
            }}
          />
          <Link to="/settings/account-info">
            <Button>Edit</Button>
          </Link>
        </div>
        <p>
          <span className="italic text-slate-500">Email: </span>
          {data.email}
        </p>
      </Card>
    </div>
  );
}
