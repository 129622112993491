export default function AboutUsPage() {
  return (
    <div className="col-span-3 overflow-y-scroll">
      <section className="flex flex-col justify-center items-center h-full">
        <div className="text-4xl font-bold text-center pb-5">
          <p>Sell and Buy</p>
          <p>clothes</p> <p>confidently.</p>
        </div>
        <div>
          <p className="">made in streetwear.</p>
        </div>
      </section>
      <section className="h-full bg-white grid grid-cols-4 grid-rows-2">
        <div className="bg-gray-500 col-span-2 row-span-2 text-8xl px-2">
          Founder
        </div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </section>
      <section className="h-full flex flex-col">
        <div className="text-8xl px-2 self-end">& Team.</div>
        <div className="flex justify-around grow items-center">
          <div className="bg-gray-500 h-1/2 aspect-square"></div>
          <div className="bg-gray-500 h-1/2 aspect-square"></div>
          <div className="bg-gray-500 h-1/2 aspect-square"></div>
        </div>
      </section>
      <section className="h-full bg-white">
        <p>How we operate</p>
      </section>
      <section className="h-full">
        <p>Our idea</p>
      </section>
    </div>
  );
}
