import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useHref,
  useNavigate,
} from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Layout from "./layout/generalLayout/Layout";
import SettingsLaout from "./layout/settingsLayout/SettingsLayout";
import WelcomeLayout from "./layout/welcomeLayout/WelcomeLayout";
import AboutUsPage from "./pages/aboutus/AboutUsPage";
import DeclarePage from "./pages/declare/DeclarePage";
import HomePage from "./pages/home/HomePage";
import LoginPage from "./pages/login/LoginPage";
import ProfileStore from "./pages/profile/ProfileStore";
import ExplorePage from "./pages/selling/ExplorePage";
import AccountInfo from "./pages/settings/AccountInfo";
import AccountPrivacy from "./pages/settings/AccountPrivacy";
import CreditsPage from "./pages/settings/CreditsPage";
import PasswordNSecurity from "./pages/settings/PasswordNSecurity";
import PrivacyPolicyPage from "./pages/settings/PrivacyPolicyPage";
import StorePrivacy from "./pages/settings/StorePrivacy";
import TermsNConditions from "./pages/settings/TermsNConditions";
import WhatYouSell from "./pages/settings/WhatYouSell";
import SignupPage from "./pages/signup/SignupPage";
import WalletPage from "./pages/wallet/WalletPage";
import WarehousePage from "./pages/warehouse/WarehousePage";
import WelcomePage from "./pages/welcome/WelcomePage";
import ServicesLayout from "./layout/ServicesLayout.js/ServicesLayout";
import Negociations from "./pages/services/Negociations";
import Stockage from "./pages/services/Stockage";
import Livraison from "./pages/services/Livraison";
import GestionArgent from "./pages/services/GestionArgent";
import AuthLayout from "./layout/authLayout/AuthLayout";

function App() {
  return (
    <Routes>
      <Route element={<WelcomeLayout />}>
        <Route path="/" element={<Navigate replace to="/welcome" />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/welcome/nos-services" element={<ServicesLayout />}>
          <Route
            path="/welcome/nos-services/negociations"
            element={<Negociations />}
          />
          <Route path="/welcome/nos-services/stockage" element={<Stockage />} />
          <Route
            path="/welcome/nos-services/livraison"
            element={<Livraison />}
          />
          <Route
            path="/welcome/nos-services/gestion-argent"
            element={<GestionArgent />}
          />
        </Route>
        <Route path="/welcome/vendre" element={<WelcomePage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/sign-up" element={<SignupPage />} />
        </Route>
      </Route>
      <Route element={<PrivateRoute />}>
        <Route element={<Layout />}>
          <Route path="/home" element={<WarehousePage />} />
          <Route path="/wallet" element={<WalletPage />} />
          <Route path="/profile" element={<ProfileStore />} />
          <Route path="/settings" element={<SettingsLaout />}>
            <Route path="/settings/account-info" element={<AccountInfo />} />
            <Route
              path="/settings/account-privacy"
              element={<AccountPrivacy />}
            />
            <Route
              path="/settings/password-security"
              element={<PasswordNSecurity />}
            />
            <Route path="/settings/what-you-sell" element={<WhatYouSell />} />

            <Route path="/settings/store-privacy" element={<StorePrivacy />} />
            <Route path="/settings/about-us" element={<AboutUsPage />} />
            <Route
              path="/settings/terms-conditions"
              element={<TermsNConditions />}
            />
            <Route path="/settings/credits" element={<CreditsPage />} />
            <Route
              path="/settings/privacy-policy"
              element={<PrivacyPolicyPage />}
            />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );
}

export default App;
