import { createContext, useEffect, useReducer } from "react";
import VintedItemReducer from "./VintedItemReducer";

const INITIAL_STATE = {
  currentUser: null,
};

export const VintedItemContext = createContext(INITIAL_STATE);

export const VintedItemContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(VintedItemReducer, INITIAL_STATE);

  return (
    <VintedItemContext.Provider
      value={{ vintedItem: state.vintedItem, dispatch }}
    >
      {children}
    </VintedItemContext.Provider>
  );
};
