import { Card } from "@nextui-org/card";
import { Outlet, useNavigate } from "react-router";
import { AcmeLogo } from "../../icons/AcmeLogo";
import { Link, NavLink } from "react-router-dom";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";

const WelcomeLayout = (props) => {
  const navigate = useNavigate();
  return (
    <div className="h-screen">
      <div className="h-[70px] relative backdrop-blur bg-[#0F0B0B]/50 z-30 flex justify-between">
        <div className="h-[70px] flex items-center justify-center sm:justify-between font-bold">
          <Link to="/welcome" className="flex items-center p-5">
            <AcmeLogo />
            FTR - Beta
          </Link>
        </div>
        <div className="flex gap-5 items-center justify-end pr-5">
          <Button variant="bordered" onPress={(e) => navigate("/login")}>
            Se connecter
          </Button>
          <Button color="primary" onPress={(e) => navigate("/sign-up")}>
            S'inscrire
          </Button>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default WelcomeLayout;
