import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "@firebase/firestore";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Chip,
  Divider,
  Input,
  Textarea,
} from "@nextui-org/react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useContext, useEffect, useState } from "react";
import Images from "../../components/images/Images";
import { AuthContext } from "../../context/AuthContext";
import { db, storage } from "../../firebase";
import { VintedItemContext } from "../../context/VintedItemContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import CrossIcon from "../../icons/CrossIcon";

export default function EditItem(props) {
  const { currentUser } = useContext(AuthContext);
  const { vintedItem } = useContext(VintedItemContext);
  const { dispatch } = useContext(VintedItemContext);
  const [data, setData] = useState(props.data);

  const fetch = async () => {
    try {
      const docRef = doc(db, "items", props.itemId);
      const docSnap = await getDoc(docRef);
      setData(docSnap.data());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetch();
  }, [props.itemId]);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    trailingZeroDisplay: "stripIfInteger",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (props.vintedItem) {
      let newData = {
        ...vintedItem,
        size: vintedItem.size.name,
        brand: vintedItem.brand.name,
        category: vintedItem.category.categoryName,
        color: vintedItem.color.name,
        condition: vintedItem.condition.name,
        price: vintedItem.price.amount,
        buyTargetMin: Math.floor(Number(vintedItem.price.amount) - 20),
        buyTargetMax: Math.floor(Number(vintedItem.price.amount) - 10),
        sellTargetMin: Math.floor(Number(vintedItem.price.amount) + 10),
        sellTargetMax: Math.floor(Number(vintedItem.price.amount) + 20),
      };

      setData(newData);
    }
  }, [vintedItem]);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => {
    if (props.vintedItem) {
      dispatch({ type: "REMOVE", payload: null });
    } else if (props.itemId) {
      searchParams.delete("editItem");
      setSearchParams(searchParams);
    }
  };

  const handleSave = async () => {
    try {
      if (props.vintedItem) {
        const docRef = await addDoc(collection(db, "items"), {
          images: data.images,
          title: data.title,
          description: data.description,
          size: data.size,
          brand: data.brand,
          condition: data.condition,
          category: data.category,
          color: data.color,
          owner: currentUser.uid,
          declaredDate: serverTimestamp(),
          vintedUrl: data.url,
          price: data.price,
          buyTargetMin: data.buyTargetMin,
          buyTargetMax: data.buyTargetMax,
          sellTargetMin: data.sellTargetMin,
          sellTargetMax: data.sellTargetMax,
          status: "saved",
        });
        console.log("Document written with ID: ", docRef.id);
        dispatch({ type: "REMOVE", payload: null });
        searchParams.append("item", docRef.id);
        setSearchParams(searchParams);
      } else if (props.itemId) {
        const itemsRef = doc(db, "items", props.itemId);

        // Set the "capital" field of the city 'DC'
        await updateDoc(itemsRef, {
          ...data,
        });
        searchParams.append("item", props.itemId);
        setSearchParams(searchParams);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="pt-5 flex flex-col gap-5">
      {data && (
        <>
          <Card>
            <CardHeader>
              <div className="w-full grid grid-cols-3 gap-5 p-2">
                <div className="flex gap-5 items-center justify-between">
                  <span className="w-full flex flex-col">
                    <span className="text-slate-500">Objectif d'achat</span>
                    <div className="flex flex-col items-center gap-2">
                      <Input
                        onBlur={() =>
                          setData({
                            ...data,
                            buyTargetMin: Math.floor(data.buyTargetMin),
                          })
                        }
                        startContent={
                          <div className="pointer-events-none flex items-center">
                            <span className="text-default-400 text-nowrap font-bold text-xl">
                              Min:
                            </span>
                          </div>
                        }
                        endContent={
                          <div className="pointer-events-none flex items-center">
                            <span className="text-default-400 text-nowrap font-bold text-3xl">
                              €
                            </span>
                          </div>
                        }
                        type="number"
                        variant="underlined"
                        classNames={{
                          input: "text-3xl font-bold",
                        }}
                        value={data.buyTargetMin}
                        onChange={(e) =>
                          setData({
                            ...data,
                            buyTargetMin: e.target.value,
                          })
                        }
                      />
                      <Input
                        onBlur={() =>
                          setData({
                            ...data,
                            buyTargetMax: Math.floor(data.buyTargetMax),
                          })
                        }
                        startContent={
                          <div className="pointer-events-none flex items-center">
                            <span className="text-default-400 font-bold text-xl">
                              Max:
                            </span>
                          </div>
                        }
                        endContent={
                          <div className="pointer-events-none flex items-center">
                            <span className="text-default-400 text-nowrap font-bold text-3xl">
                              €
                            </span>
                          </div>
                        }
                        type="number"
                        variant="underlined"
                        classNames={{
                          input: "text-3xl font-bold",
                        }}
                        value={data.buyTargetMax}
                        onChange={(e) =>
                          setData({
                            ...data,
                            buyTargetMax: e.target.value,
                          })
                        }
                      />
                    </div>
                  </span>
                  <Divider orientation="vertical" />
                </div>
                <div className="flex gap-5 items-center justify-between">
                  <span className="w-full flex flex-col">
                    <span className="text-slate-500">Objectif de vente</span>
                    <div className="flex flex-col items-center gap-2">
                      <Input
                        onBlur={() =>
                          setData({
                            ...data,
                            sellTargetMin: Math.floor(data.sellTargetMin),
                          })
                        }
                        startContent={
                          <div className="pointer-events-none flex items-center">
                            <span className="text-default-400 text-nowrap font-bold text-xl">
                              Min:
                            </span>
                          </div>
                        }
                        endContent={
                          <div className="pointer-events-none flex items-center">
                            <span className="text-default-400 text-nowrap font-bold text-3xl">
                              €
                            </span>
                          </div>
                        }
                        type="number"
                        variant="underlined"
                        classNames={{
                          input: "text-3xl font-bold",
                        }}
                        value={data.sellTargetMin}
                        onChange={(e) =>
                          setData({
                            ...data,
                            sellTargetMin: e.target.value,
                          })
                        }
                      />
                      <Input
                        onBlur={() =>
                          setData({
                            ...data,
                            sellTargetMax: Math.floor(data.sellTargetMax),
                          })
                        }
                        startContent={
                          <div className="pointer-events-none flex items-center">
                            <span className="text-default-400 font-bold text-xl">
                              Max:
                            </span>
                          </div>
                        }
                        endContent={
                          <div className="pointer-events-none flex items-center">
                            <span className="text-default-400 text-nowrap font-bold text-3xl">
                              €
                            </span>
                          </div>
                        }
                        type="number"
                        variant="underlined"
                        classNames={{
                          input: "text-3xl font-bold",
                        }}
                        value={data.sellTargetMax}
                        onChange={(e) =>
                          setData({
                            ...data,
                            sellTargetMax: e.target.value,
                          })
                        }
                      />
                    </div>
                  </span>
                  <Divider orientation="vertical" />
                </div>

                <div className="flex gap-5 items-center justify-between">
                  <span className="w-full flex flex-col">
                    <div className="flex justify-between text-slate-500">
                      Benefices
                    </div>
                    <span className="text-green-500 text-3xl font-bold">
                      {data.sellTargetMin - data.buyTargetMax}-
                      {data.sellTargetMax - data.buyTargetMin}€
                    </span>
                  </span>
                </div>
              </div>
            </CardHeader>
            <Divider />
            <CardBody>
              <div className="mb-3 flex justify-between items-center">
                <div className="flex gap-2 text-2xl font-bold">
                  <span className="text-slate-500 text-md">
                    Prix sur l'anonce:{" "}
                  </span>
                  {data.price}€
                </div>
                <div className="cursor-pointer" onClick={handleClose}>
                  <CrossIcon size={30} color="white" />
                </div>
              </div>
              <p className="text-nowrap w-full overflow-x-scroll text-gray-500 italic">
                {data.url}
              </p>
              <Images imageList={data.images} />
              <div className="pt-5 flex flex-col gap-3">
                <Input
                  label="Titre"
                  variant="bordered"
                  value={data.title}
                  onChange={(e) => setData({ ...data, title: e.target.value })}
                />
                <Textarea
                  label="Description"
                  variant="bordered"
                  value={data.description}
                  onChange={(e) =>
                    setData({ ...data, description: e.target.value })
                  }
                />
                <div className="flex gap-3">
                  <Input
                    label="Size"
                    variant="bordered"
                    value={data.size}
                    onChange={(e) => setData({ ...data, size: e.target.value })}
                  />
                  <Input
                    label="Brand"
                    variant="bordered"
                    value={data.brand}
                    onChange={(e) =>
                      setData({ ...data, brand: e.target.value })
                    }
                  />
                  <Input
                    label="Condition"
                    variant="bordered"
                    value={data.condition}
                    onChange={(e) =>
                      setData({ ...data, condition: e.target.value })
                    }
                  />
                </div>
                <div className="flex gap-3">
                  <Input
                    label="Category"
                    variant="bordered"
                    value={data.category}
                    onChange={(e) =>
                      setData({
                        ...data,
                        category: e.target.value,
                      })
                    }
                  />
                  <Input
                    label="Color"
                    variant="bordered"
                    value={data.color}
                    onChange={(e) =>
                      setData({ ...data, color: e.target.value })
                    }
                  />
                  <Button
                    onPress={handleSave}
                    variant="bordered"
                    color="primary"
                    className="h-[56px]"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  );
}
