import { Card } from "@nextui-org/card";
import {
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Tab,
  Tabs,
} from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import List from "../../components/list/List";
import { SearchIcon } from "../../icons/SearchIcon";
import { VintedItemContext } from "../../context/VintedItemContext";
import { collection, onSnapshot, query, where } from "@firebase/firestore";
import { db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import EmptyIcon from "../../icons/EmptyIcon";

const WarehousePage = (props) => {
  const [vintedInput, setVintedInput] = useState("");
  const [vintedItem, setVintedItem] = useState();
  const navigate = useNavigate();

  const { dispatch } = useContext(VintedItemContext);

  const handleVintedSearch = () => {
    let productId = vintedInput.split("/").slice(-1)[0].split("-")[0];
    fetch(
      "https://vinted3.p.rapidapi.com/getProduct?country=us?" +
        new URLSearchParams({
          page: "1",
          productId: productId,
        }).toString(),
      {
        method: "GET",
        headers: {
          "x-rapidapi-host": "vinted3.p.rapidapi.com",
          "x-rapidapi-key":
            "bc873b7343msh744f79867c8a323p18258bjsn813565a5b026",
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        dispatch({ type: "ADD", payload: response });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [items, setItems] = useState([]);
  const [itemsSold, setItemsSold] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const unsub = onSnapshot(
      query(
        collection(db, "items"),
        where("owner", "==", currentUser.uid),
        where("status", "!=", "saved")
      ),
      (snapShot) => {
        let list = [];
        let listSold = [];
        snapShot.docs.forEach((doc) => {
          if (doc.data().status == "sold") {
            listSold.push({ id: doc.id, ...doc.data() });
          } else {
            list.push({ id: doc.id, ...doc.data() });
          }
        });
        setItems([...list]);
        setItemsSold([...listSold]);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  return (
    <div className="flex flex-col gap-5 pt-5">
      <div className="flex flex-col gap-5">
        <Card className="p-5">
          <span className="text-xl font-bold mb-5">
            Importer un article Vinted
          </span>
          <div className="flex flex-row gap-5">
            <Input
              className="min-w-[300px]"
              placeholder="Entrez le lien Vinted de l'article desire"
              startContent={<SearchIcon size={18} />}
              type="search"
              variant="underlined"
              value={vintedInput}
              onValueChange={setVintedInput}
            />
            <Button
              color={vintedInput != "" ? "primary" : "default"}
              onPress={handleVintedSearch}
            >
              Importer
            </Button>
          </div>
        </Card>
      </div>

      <Card className="p-5 flex flex-col gap-5">
        <span className="text-xl font-bold">En cours de revente</span>
        <div className="flex flex-row gap-5 mb-5">
          <Input
            placeholder="Recherchez les articles sauvegrades, en cours d'achat et achetes..."
            startContent={<SearchIcon size={18} />}
            type="search"
            variant="underlined"
          />
          <Dropdown>
            <DropdownTrigger>
              <Button variant="bordered">Trier</Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions">
              <DropdownItem key="new">Plus Recent</DropdownItem>
              <DropdownItem key="copy">Plus Ancien</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <List
          isLoading={isLoading}
          data={items}
          emptyMessage={
            <div className="flex flex-col items-center">
              <EmptyIcon size={30} color="#94A3B8" />
              <span className="flex text-slate-400 items-center gap-2 mb-5">
                Commencez a revendre un article (Prets a etre revendus)
              </span>
            </div>
          }
        />
      </Card>
      <Card className="p-5">
        <span className="text-xl font-bold mb-5">Vendus recement</span>
        <List
          isLoading={isLoading}
          data={itemsSold}
          emptyMessage={
            <div className="flex flex-col items-center">
              <EmptyIcon size={30} color="#94A3B8" />
              <span className="flex text-slate-400 items-center gap-2 mb-5">
                Commencez a revendre un article (Prets a etre revendus)
              </span>
            </div>
          }
        />
      </Card>
    </div>
  );
};

export default WarehousePage;
