const StorageIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7 21v-9.4c0-.56 0-.84.109-1.054a1 1 0 0 1 .437-.437C7.76 10 8.04 10 8.6 10h6.8c.56 0 .84 0 1.054.109a1 1 0 0 1 .437.437C17 10.76 17 11.04 17 11.6V21m-7-7h4m-4 4h4M3 10.488V19.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C3.76 21 4.04 21 4.6 21h14.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437C21 20.24 21 19.96 21 19.4v-8.912c0-1.074 0-1.61-.163-2.085a3 3 0 0 0-.69-1.119c-.35-.359-.83-.6-1.789-1.083l-4.2-2.115c-.79-.398-1.186-.597-1.602-.675a3 3 0 0 0-1.112 0c-.416.078-.811.277-1.602.675l-4.2 2.115c-.96.483-1.439.724-1.79 1.083a3 3 0 0 0-.689 1.12C3 8.876 3 9.413 3 10.487"
    ></path>
  </svg>
);

export default StorageIcon;
