import { Spinner } from "@nextui-org/react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase";
import ItemCard from "../card/ItemCard";

const List = (props) => {
  return (
    <>
      {!props.isLoading ? (
        <>
          {props.data.length > 0 ? (
            <div className="gap-2 grid grid-cols-2 sm:grid-cols-3">
              {props.data.map((item) => (
                <div key={item.id}>
                  <ItemCard type={item.type} data={item} />
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full flex items-center justify-center">
              {props.emptyMessage}
            </div>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default List;
