import { Card } from "@nextui-org/card";
import { Accordion, AccordionItem, Button, Chip } from "@nextui-org/react";
import { Link } from "react-router-dom";
import ItemCard from "../../components/card/ItemCard";
import vintedScreenshot from "../../icons/VintedScreenshot.png";
import TickIcon from "../../icons/TickIcon";
import { motion } from "framer-motion";
import SimpleArrowRight from "../../icons/SimpleArrowRight";
import backgroundImg from "../../icons/background.jpg";
import { Header } from "../../layout/selectedItem/actions/Actions";
import VintedIcon from "../../icons/VintedIcon";
import ImportIcon from "../../icons/ImportIcon";
import NegotiationIcon from "../../icons/NegotiationIcon";
import TruckIcon from "../../icons/TruckIcon";
import CameraIcon from "../../icons/CameraIcon";
import StorageIcon from "../../icons/StorageIcon";
import MoneyIcon from "../../icons/MoneyIcon";
import WriteIcon from "../../icons/WriteIcon";

const itemData = {
  id: "AJwqTkkav6HrwHK0pXci",
  sellTarget: 119,
  buyTarget: 99,
  title: "Pull Stone Island Blanc",
  brand: "Stone Island",
  owner: "1DqlJiRNTDQNFm5pcl9yFOu5CUv2",
  category: "Sweaters",
  color: "White",
  declaredDate: {
    seconds: 1729691225,
    nanoseconds: 135000000,
  },
  status: "saved",
  images: [
    "https://images1.vinted.net/tc/02_01dfd_iWLrrqZwkaCsFbuqJWYGkqcY/1729093891.jpeg?s=4df91b7d1ced8c70933f0832f2c7a84c6caca4fe",
    "https://images1.vinted.net/tc/02_00375_vbrrm3xPdABGghgbHwk6PyvK/1729093891.jpeg?s=1cf8a0e81053feb7ae121a6f85fc42240d356430",
    "https://images1.vinted.net/tc/03_00207_D2wDUbZT2GJ7MMqCDC6FKLu2/1729093891.jpeg?s=e02bd17a88348291617b548a5255cd16eb2d12a2",
    "https://images1.vinted.net/tc/03_015ce_k6N2Q1idBKrbFN3Nzu6fvw3X/1729093891.jpeg?s=33676527b2d0797d12323647e46f09633bf37db7",
    "https://images1.vinted.net/tc/02_001cd_WBTeQUcnoYBez5B8UFr9AbUK/1729093891.jpeg?s=279bbcdec7f2021dd50327d7cd0653162f14de0f",
    "https://images1.vinted.net/tc/02_018a1_CodbC251Nk8RjdgvQdCsCoue/1729093891.jpeg?s=8e06c8bbb8b2937061c97102521790e9efa8c8e5",
    "https://images1.vinted.net/tc/03_0017d_iTBpaQ9opPy7MdkzJngZp9nn/1729093891.jpeg?s=1085179eb7675d1392b456da76b9cccddcfba884",
    "https://images1.vinted.net/tc/03_00bdc_ZwCL5mHUqyUynW4QUTtEJ1GN/1729093891.jpeg?s=cc3d4af312af88b06c418c82be7a96be048dc63f",
    "https://images1.vinted.net/tc/02_01a05_yPCMTWTkaLkcRwB883sXzgNC/1729093891.jpeg?s=8656a00c6e11c969ae34950d6127be11adf9d580",
  ],
  vintedUrl: "https://www.vinted.com/items/5228336600-pull-stone-island-blanc",
  size: "M",
  description:
    "article porté quelques fois présente quelques défauts (voir photos)\n100 % authentique ✅✅\nN’hésitez pas à me contacter pour plus de renseignements",
  condition: "Good",
  price: "109.99",
};

const WelcomePage = () => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    trailingZeroDisplay: "stripIfInteger",
  });
  return (
    <div className="px-5 h-screen overflow-y-scroll mt-[-70px]">
      <div className="h-screen grid grid-cols-2">
        <div className="max-w-[500px] flex flex-col gap-5 place-self-center">
          <div className="text-4xl font-bold">
            Revendez sur Vinted{" "}
            <span className="tracking-tight inline font-semibold from-[#FF1CF7] to-[#b249f8] text-[2.5rem] bg-clip-text text-transparent bg-gradient-to-b">
              facilement
            </span>{" "}
            sans jamais toucher l'article
          </div>
          <div className="text-xl text-slate-500">
            Notre solution pour automatiser le Resell Vinted
          </div>
          <div className="flex gap-2">
            <motion.button
              style={{ width: "160px" }}
              whileHover={{
                width: 200,
                transition: { duration: 0.2 },
              }}
              whileTap={{ width: 200 }}
            >
              <Link to="/sign-up">
                <Chip
                  classNames={{ base: "p-5 max-w-full min-w-full w-full" }}
                  endContent={<SimpleArrowRight color="white" size={25} />}
                  size="lg"
                  color="primary"
                >
                  <span className="flex justify-start">C'est parti</span>
                </Chip>
              </Link>
            </motion.button>
            <Link to="/login">
              <Chip classNames={{ base: "p-5" }} size="lg" variant="bordered">
                Se connecter
              </Chip>
            </Link>
          </div>
        </div>
        <div className="flex items-center justify-center pr-10">
          <div className="grid grid-cols-2">
            <div className="">
              <motion.div
                animate={{ y: 20 }}
                transition={{
                  repeat: Infinity,
                  repeatType: "reverse",
                  duration: 3,
                  ease: "easeInOut",
                  delay: 0.5,
                }}
              >
                <img
                  className="max-w-[400px] rounded-md"
                  src={vintedScreenshot}
                />
              </motion.div>

              <motion.div
                animate={{ y: 20 }}
                transition={{
                  repeat: Infinity,
                  repeatType: "reverse",
                  duration: 3,
                  ease: "easeInOut",
                  delay: 0.5,
                }}
                className="pt-5 pl-5 flex flex-col gap-2"
              >
                <Chip
                  startContent={<TickIcon color="#0966D9" size={20} />}
                  className="ml-5"
                >
                  Experts en negotiacions
                </Chip>
                <Chip startContent={<TickIcon color="#0966D9" size={20} />}>
                  Photos professionnels
                </Chip>
                <Chip
                  startContent={<TickIcon color="#0966D9" size={20} />}
                  className="ml-5"
                >
                  Stockage
                </Chip>
                <Chip
                  startContent={<TickIcon color="#0966D9" size={20} />}
                  className="ml-10"
                >
                  Livraison
                </Chip>
              </motion.div>
            </div>
            <motion.div
              animate={{ y: -30 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 3,
                ease: "easeInOut",
                delay: 1,
              }}
              className="pt-[60px] max-w-[400px] flex flex-col gap-2 items-center"
            >
              <ItemCard type="selling" data={itemData} />
            </motion.div>
            <motion.div
              animate={{ y: 30 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 3,
                ease: "easeInOut",
                delay: 1.5,
              }}
              className="mt-[-30px] mr-[-50px] ml-[50px] z-20"
            >
              <Card>
                <div className="p-5">Benefices: $320</div>
              </Card>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="h-screen flex flex-col items-center justify-between pb-20">
        <div className="grow flex flex-col items-center justify-center">
          <div className="grid grid-cols-3 gap-20 grid-cols-[40%_10%_40%]">
            <div className="w-full">
              <span className="flex justify-center text-3xl font-bold mb-10 text-primary">
                Achat
              </span>
              <Accordion>
                <AccordionItem
                  key={1}
                  aria-label="Vinted"
                  title={
                    <span className="flex gap-2">
                      <VintedIcon color="white" size={30} />
                      Trouvez un article sur Vinted
                    </span>
                  }
                >
                  Trouvez un article gangant sur Vinted
                </AccordionItem>
                <AccordionItem
                  key={2}
                  aria-label="Vinted"
                  title={
                    <span className="flex gap-2">
                      <ImportIcon color="white" size={30} />
                      Importez le dans FT-R
                    </span>
                  }
                >
                  Importez dans FT-R
                </AccordionItem>
                <AccordionItem
                  key={3}
                  aria-label="Vinted"
                  title={
                    <span className="flex gap-2">
                      <WriteIcon color="white" size={30} />
                      Entrez l'objectif d'achat & de vente
                    </span>
                  }
                >
                  Trouvez un article gangant sur Vinted
                </AccordionItem>
                <AccordionItem
                  key={4}
                  aria-label="Vinted"
                  title={
                    <span className="flex gap-2">
                      <NegotiationIcon color="white" size={30} />
                      On negocie selon vos demandes
                    </span>
                  }
                >
                  Trouvez un article gangant sur Vinted
                </AccordionItem>

                <AccordionItem
                  key={5}
                  aria-label="Vinted"
                  title={
                    <span className="flex gap-2">
                      <TruckIcon color="white" size={30} />
                      On l'achete et on l'envoie chez nous
                    </span>
                  }
                >
                  Trouvez un article gangant sur Vinted
                </AccordionItem>
              </Accordion>
            </div>
            <div className="flex items-center justify-center">
              <SimpleArrowRight color="white" size={50} />
            </div>
            <div className="w-full">
              <span className="flex justify-center text-3xl font-bold mb-10 text-secondary">
                Revente
              </span>
              <Accordion>
                <AccordionItem
                  key={6}
                  aria-label="Vinted"
                  title={
                    <span className="flex gap-2">
                      <CameraIcon color="white" size={30} />
                      On prend des photos professionnels
                    </span>
                  }
                >
                  Trouvez un article gangant sur Vinted
                </AccordionItem>
                <AccordionItem
                  key={7}
                  aria-label="Vinted"
                  title={
                    <span className="flex gap-2">
                      <StorageIcon color="white" size={30} />
                      On le stock jusqu'a vente
                    </span>
                  }
                >
                  Trouvez un article gangant sur Vinted
                </AccordionItem>
                <AccordionItem
                  key={8}
                  aria-label="Vinted"
                  title={
                    <span className="flex gap-2">
                      <VintedIcon color="white" size={30} />
                      On met votre article sur Vinted
                    </span>
                  }
                >
                  Trouvez un article gangant sur Vinted
                </AccordionItem>
                <AccordionItem
                  key={9}
                  aria-label="Vinted"
                  title={
                    <span className="flex gap-2">
                      <TruckIcon color="white" size={30} />
                      On envoie votre article a l'acheteur
                    </span>
                  }
                >
                  Trouvez un article gangant sur Vinted
                </AccordionItem>
                <AccordionItem
                  key={10}
                  aria-label="Vinted"
                  title={
                    <span className="flex gap-2">
                      <MoneyIcon color="white" size={30} />
                      Vous recuperez les benefices
                    </span>
                  }
                >
                  Trouvez un article gangant sur Vinted
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
        <div className="">
          <Link to="/sign-up" className="flex gap-2 items-center">
            <Chip size="lg" color="primary">
              <span className="flex justify-start">Inscrivez-vous</span>
            </Chip>
            et decouvrez par vous memes
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
