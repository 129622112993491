import { Card, Listbox, ListboxItem, ListboxSection } from "@nextui-org/react";
import { getAuth } from "firebase/auth";
import { useContext } from "react";
import { Outlet } from "react-router";
import { AuthContext } from "../../context/AuthContext";
import { app } from "../../pages/firebase.config";

export default function SettingsLaout() {
  const itemClasses = {
    heading: "w-full",
    base: "w-full p-0",
    trigger: "w-full p-0",
    title: "w-full text-sm p-0",
    content: "text-small flex flex-col px-2",
  };

  const { dispatch } = useContext(AuthContext);

  function handleLogout() {
    const auth = getAuth(app);
    auth.signOut();
    dispatch({ type: "LOGOUT", payload: "" });
  }

  return (
    <div className="pt-5">
      <Card>
        <div className="p-2 grid grid-cols-2 grid-cols-[33%_67%] grid-rows-1 grid-rows-[1fr] max-h-screen pb-[80px]">
          <Listbox
            itemClasses={{
              title: "w-full",
            }}
            className=""
            variant="flat"
            aria-label="Listbox menu with sections"
          >
            <ListboxSection title="Your Account" showDivider>
              <ListboxItem href="/settings/account-info" key="usernameninfo">
                Username & Info
              </ListboxItem>

              <ListboxItem
                href="/settings/password-security"
                key="passwordnsecurity"
              >
                Password & Security
              </ListboxItem>
              <ListboxItem href="/wallet" key="copy">
                Wallet
              </ListboxItem>
            </ListboxSection>
            <ListboxSection title="More..." showDivider>
              <ListboxItem href="/settings/about-us" key="aboutus">
                About Us
              </ListboxItem>
              <ListboxItem
                href="/settings/terms-conditions"
                key="termsnconditions"
              >
                Terms & Conditions
              </ListboxItem>
              <ListboxItem href="/settings/credits" key="credits">
                Credits
              </ListboxItem>
              <ListboxItem href="/settings/privacy-policy" key="privacypolicy">
                Privacy Policy
              </ListboxItem>
            </ListboxSection>
            <ListboxSection title="Log out">
              <ListboxItem
                key="logout"
                className="text-danger"
                color="danger"
                onPress={() => handleLogout()}
              >
                Log out
              </ListboxItem>
            </ListboxSection>
          </Listbox>
          <Outlet />
        </div>
      </Card>
    </div>
  );
}
