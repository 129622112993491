const ImportIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={props.color}
      d="m12 14-.707.707.707.707.707-.707zm1-9a1 1 0 1 0-2 0zM6.293 9.707l5 5 1.414-1.414-5-5zm6.414 5 5-5-1.414-1.414-5 5zM13 14V5h-2v9z"
    ></path>
    <path
      stroke={props.color}
      strokeWidth="2"
      d="M5 16v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1"
    ></path>
  </svg>
);

export default ImportIcon;
