import { getAuth, signOut } from "firebase/auth";

const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN": {
      return {
        currentUser: action.payload,
      };
    }
    case "LOGOUT": {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Sign-out successful.
        })
        .catch((error) => {
          // An error happened.
        });
      return {
        currentUser: null,
      };
    }
    default:
      return state;
  }
};

export default AuthReducer;
