const EmptyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m9.5 11 5 5M14.5 11l-5 5"
    ></path>
    <path
      stroke={props.color}
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17.828 6.828c.579.578.867.868 1.02 1.235.152.368.152.776.152 1.594V17c0 1.886 0 2.828-.586 3.414S16.886 21 15 21H9c-1.886 0-2.828 0-3.414-.586S5 18.886 5 17V7c0-1.886 0-2.828.586-3.414S7.114 3 9 3h3.343c.818 0 1.226 0 1.594.152s.657.442 1.235 1.02z"
    ></path>
  </svg>
);

export default EmptyIcon;
