import { Divider, Switch } from "@nextui-org/react";
import { useState } from "react";

export default function StorePrivacy() {
  const [isSelected, setIsSelected] = useState(true);

  return (
    <div className="p-5 flex flex-col gap-2">
      <h2>Store 1 Privacy</h2>
      <Switch isSelected={isSelected} onValueChange={setIsSelected}>
        Private store
      </Switch>
      <Divider />
      <Switch isSelected={isSelected} onValueChange={setIsSelected}>
        Don't show your store in searches
      </Switch>
      <Divider />
      <Switch isSelected={isSelected} onValueChange={setIsSelected}>
        Disable your store to be favorited
      </Switch>
      <p className="text-small text-default-500">
        Selected: {isSelected ? "true" : "false"}
      </p>
      <Divider />
      <h2>Store 2 Privacy</h2>
      <Switch isSelected={isSelected} onValueChange={setIsSelected}>
        Private store
      </Switch>
      <Divider />
      <Switch isSelected={isSelected} onValueChange={setIsSelected}>
        Don't show your store in searches
      </Switch>
      <Divider />
      <Switch isSelected={isSelected} onValueChange={setIsSelected}>
        Disable your store to be favorited
      </Switch>
      <p className="text-small text-default-500">
        Selected: {isSelected ? "true" : "false"}
      </p>
    </div>
  );
}
